/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Route } from 'vue-router';

const routes = [
  { path: '/', name: 'trendsHome', component: () => import(/* webpackChunkName: "TrendsHome" */ '../views/TrendsHome.vue') },
  { path: '/templates/:id', name: 'createTrendFromTemplate', component: () => import(/* webpackChunkName: "CreateTrendFromTemplate" */ '../views/CreateTrendFromTemplate.vue'), props: true },
  { path: '/trends/new', name: 'newTrend', beforeEnter: () => (window.location.href = '/trends/new') },
  { path: '/trends/newInNewWindow', name: 'newTrendInNewWindow', beforeEnter: () => window.open('/trends/new', '_blank') },
  { path: '/trends/:id', name: 'trendEditor', component: () => import(/* webpackChunkName: "TrendEditor" */ '../views/TrendEditor.vue'), props: true },
  {
    path: '/artifacts/:id',
    name: 'artifact',
    props: true,
    beforeEnter: (to: Route): void => {
      window.location.replace(`/a/#/artifacts/${to.params.id}`);
    }
  },
  {
    path: '/embeddables/:id',
    name: 'embeddables',
    props: true,
    beforeEnter: (to: Route): void => {
      window.location.replace(`/u/#/embeddables/${to.params.id}`);
    }
  }
];

export { routes };

export default routes;
