import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { TrendSummaryResourceDto, TrendPublishDto, FileResponse } from '@/repositories';

// state
interface ITrendsStoreState {
  trends: TrendSummaryResourceDto[];
}

// vuex module
class TrendsStore implements Module<ITrendsStoreState, IRootState> {
  namespaced = true;

  // state
  state: ITrendsStoreState = {
    trends: [] as TrendSummaryResourceDto[]
  };

  // getters
  getters = {
    trends(s: ITrendsStoreState): TrendSummaryResourceDto[] {
      return s.trends;
    },

    savedTrends(s: ITrendsStoreState): TrendSummaryResourceDto[] {
      return s.trends.filter((t) => !t.trendSummary?.ownerIsAGrId && !t.trendSummary?.isDraft);
    },

    savedGridTrends(s: ITrendsStoreState): TrendSummaryResourceDto[] {
      return s.trends.filter((t) => t.trendSummary?.ownerIsAGrId && !t.trendSummary?.isDraft);
    }
  };

  // mutations
  mutations = {
    loadTrends(s: ITrendsStoreState, value: TrendSummaryResourceDto[]): void {
      s.trends = value;
    },

    deleteTrend(s: ITrendsStoreState, id: string): void {
      s.trends = s.trends.filter((x) => x.trendSummary?.id !== id);
    }
  };

  // actions
  actions = {
    async loadTrends({ rootState, commit }: ActionContext<ITrendsStoreState, IRootState>): Promise<void> {
      const trends = await rootState.container.trendsApiClient.getAll();
      commit('loadTrends', trends);
    },

    async deleteTrend({ commit, rootState }: ActionContext<ITrendsStoreState, IRootState>, id: string): Promise<void> {
      await rootState.container.trendsApiClient.delete(id);
      commit('deleteTrend', id);
    },

    async claimGridTrend({ dispatch, rootState }: ActionContext<ITrendsStoreState, IRootState>, id: string): Promise<FileResponse> {
      const result = await rootState.container.trendsApiClient.claim(id);
      if (result.status === 200) {
        dispatch('loadTrends');
      }
      return result;
    },

    async publishTrend({ rootState }: ActionContext<ITrendsStoreState, IRootState>, { id, trendPublishDto }: { id: string; trendPublishDto: TrendPublishDto }): Promise<FileResponse> {
      return await rootState.container.trendsApiClient.publish(id, trendPublishDto);
    }
  };
}

export { ITrendsStoreState, TrendsStore };

// export an instance of the store by default
export default new TrendsStore();
