import IRootState from '../../common/stores/rootState';
import artifactsStore from './artifactsStore';
import artifactStore from '../../common/stores/artifactStore';
import authorisationsStore from './authorisationsStore';
import homeStore from './homeStore';
import templatesStore from './templatesStore';
import trendStore from './trendStore';
import trendsStore from './trendsStore';
import organisationsStore from './organisationsStore';
import { ModuleTree } from 'vuex';

const stores = {
  artifactsStore,
  authorisationsStore,
  homeStore,
  templatesStore,
  trendStore,
  trendsStore,
  organisationsStore,
  artifactStore
} as ModuleTree<IRootState>;

export * from '../../common/stores/rootState';
export * from './artifactsStore';
export * from '../../common/stores/artifactStore';
export * from './authorisationsStore';
export * from './homeStore';
export * from './templatesStore';
export * from './trendStore';
export * from './trendsStore';
export * from './organisationsStore';

export { stores, artifactStore, artifactsStore, authorisationsStore, homeStore, templatesStore, trendStore, trendsStore, organisationsStore };
export default stores;
