import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { ArtifactsSearchResultsDto, FileResponse } from '@/repositories';
import { Moment } from 'moment';

// state
interface IArtifactsStoreState {
  artifactsSearchResults: ArtifactsSearchResultsDto | undefined;
}

// vuex module
class ArtifactsStore implements Module<IArtifactsStoreState, IRootState> {
  namespaced = true;

  // state
  state: IArtifactsStoreState = {
    artifactsSearchResults: undefined
  };

  // getters
  getters = {
    artifactsSearchResults(s: IArtifactsStoreState): ArtifactsSearchResultsDto | undefined {
      return s.artifactsSearchResults;
    }
  };

  // mutations
  mutations = {
    commitSearchResults(s: IArtifactsStoreState, value: ArtifactsSearchResultsDto): void {
      s.artifactsSearchResults = value;
    },

    deleteArtifact(s: IArtifactsStoreState, id: string): void {
      if (s.artifactsSearchResults?.trends) s.artifactsSearchResults.trends = s.artifactsSearchResults?.trends?.filter((x) => x.id !== id);
    }
  };

  // actions
  actions = {
    async searchArtifacts({ rootState, commit }: ActionContext<IArtifactsStoreState, IRootState>, { userId, query }: { userId: string | undefined; query: string | undefined }): Promise<void> {
      const searchResults = await rootState.container.artifactsApiClient.search(userId, query);
      commit('commitSearchResults', searchResults);
    },

    async createArtifactFromTrend({ rootState }: ActionContext<IArtifactsStoreState, IRootState>, trendId: string): Promise<FileResponse> {
      return await rootState.container.artifactsApiClient.put(trendId);
    },

    async deleteArtifact({ rootState, commit }: ActionContext<IArtifactsStoreState, IRootState>, id: string): Promise<FileResponse> {
      const result = await rootState.container.artifactsApiClient.delete(id);
      commit('deleteArtifact', id);
      return result;
    },

    async getLastSaved({ rootState }: ActionContext<IArtifactsStoreState, IRootState>, id: string): Promise<Moment | null> {
      return await rootState.container.artifactsApiClient.getLastSaved(id);
    }
  };
}

export { IArtifactsStoreState, ArtifactsStore };

// export an instance of the store by default
export default new ArtifactsStore();
