import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { TrendEntryPointArgs } from '@/repositories';

// state
interface ITrendStoreState {
  trendEntryPointArgs: TrendEntryPointArgs | undefined;
}

// vuex module
class TrendStore implements Module<ITrendStoreState, IRootState> {
  namespaced = true;

  // state
  state: ITrendStoreState = {
    trendEntryPointArgs: undefined
  };

  // getters
  getters = {
    trendEntryPointArgs(s: ITrendStoreState): TrendEntryPointArgs | undefined {
      return s.trendEntryPointArgs;
    }
  };

  // mutations
  mutations = {
    loadTrendEntryPointArgs(s: ITrendStoreState, value: TrendEntryPointArgs): void {
      s.trendEntryPointArgs = value;
    }
  };

  // actions
  actions = {
    async loadTrendEntryPointArgs({ rootState, commit }: ActionContext<ITrendStoreState, IRootState>, id: string): Promise<void> {
      const trendEntryPointArgs = await rootState.container.entryPointArgsApiClient.trend(id);
      commit('loadTrendEntryPointArgs', trendEntryPointArgs);
    }
  };
}

export { ITrendStoreState, TrendStore };

// export an instance of the store by default
export default new TrendStore();
