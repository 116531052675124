import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { HomeEntryPointArgs } from '@/repositories';

// state
interface IHomeStoreState {
  homeEntryPointArgs: HomeEntryPointArgs | undefined;
}

// vuex module
class HomeStore implements Module<IHomeStoreState, IRootState> {
  namespaced = true;

  // state
  state: IHomeStoreState = {
    homeEntryPointArgs: undefined
  };

  // getters
  getters = {
    homeEntryPointArgs(s: IHomeStoreState): HomeEntryPointArgs | undefined {
      return s.homeEntryPointArgs;
    }
  };

  // mutations
  mutations = {
    loadHomeEntryPointArgs(s: IHomeStoreState, value: HomeEntryPointArgs): void {
      s.homeEntryPointArgs = value;
    }
  };

  // actions
  actions = {
    async loadHomeEntryPointArgs({ rootState, commit }: ActionContext<IHomeStoreState, IRootState>): Promise<void> {
      const homeEntryPointArgs = await rootState.container.entryPointArgsApiClient.home();
      commit('loadHomeEntryPointArgs', homeEntryPointArgs);
    }
  };
}

export { IHomeStoreState, HomeStore };

// export an instance of the store by default
export default new HomeStore();
