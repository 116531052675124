import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { Organisation } from '@/repositories';

// state
interface IOrganisationsStoreState {
  organisations: Organisation[];
}

// vuex module
class OrganisationsStore implements Module<IOrganisationsStoreState, IRootState> {
  namespaced = true;

  // state
  state: IOrganisationsStoreState = {
    organisations: []
  };

  // getters
  getters = {
    organisations(s: IOrganisationsStoreState): Organisation[] {
      return s.organisations;
    }
  };

  // mutations
  mutations = {
    loadOrganisations(s: IOrganisationsStoreState, value: Organisation[]): void {
      s.organisations = value;
    }
  };

  // actions
  actions = {
    async loadOrganisations({ rootState, commit }: ActionContext<IOrganisationsStoreState, IRootState>): Promise<void> {
      const organisations = await rootState.container.organisationsApiClient.getAll();
      commit('loadOrganisations', organisations);
    }
  };
}

export { IOrganisationsStoreState, OrganisationsStore };

// export an instance of the store by default
export default new OrganisationsStore();
