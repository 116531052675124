import 'whatwg-fetch';
import '../common.entry';

import { routes } from './routes';
import { stores } from './stores';
import { runApp } from '../common/main';

const authenticationPolicies = ['Licensed'];

runApp(routes, stores, authenticationPolicies);
