import { ActionContext, Module } from 'vuex';
import { TemplateSummaryDto, FileResponse, TrendResourceDto } from '@/repositories';
import { IRootState } from '.';

// state
interface ITemplatesStoreState {
  templates: TemplateSummaryDto[];
}

// vuex module
class TemplatesStore implements Module<ITemplatesStoreState, IRootState> {
  namespaced = true;

  // state
  state: ITemplatesStoreState = {
    templates: [] as TemplateSummaryDto[]
  };

  // getters
  getters = {
    templates(s: ITemplatesStoreState): TemplateSummaryDto[] {
      return s.templates;
    }
  };

  // mutations
  mutations = {
    loadTemplates(s: ITemplatesStoreState, value: TemplateSummaryDto[]): void {
      s.templates = value;
    },

    removeTemplate(s: ITemplatesStoreState, id: string): void {
      s.templates = s.templates.filter((x) => x.id !== id);
    }
  };

  // actions
  actions = {
    async loadTemplates({ rootState, commit }: ActionContext<ITemplatesStoreState, IRootState>): Promise<void> {
      const templates = await rootState.container.templatesApiClient.getAll();
      commit('loadTemplates', templates);
    },

    async createTrendFromTemplate({ rootState }: ActionContext<ITemplatesStoreState, IRootState>, id: string): Promise<TrendResourceDto> {
      return await rootState.container.templatesApiClient.createTrend(id);
    },

    async removeTemplate({ commit, rootState }: ActionContext<ITemplatesStoreState, IRootState>, id: string): Promise<FileResponse> {
      const result = await rootState.container.templatesApiClient.delete(id);
      commit('removeTemplate', id);
      return result;
    }
  };
}

export { ITemplatesStoreState, TemplatesStore };

// export an instance of the store by default
export default new TemplatesStore();
