import { ActionContext, Module } from 'vuex';
import { IRootState } from '.';
import { IsAuthorisedDto } from '@/repositories';

// state
interface IAuthorisationsStoreState {
  fake: boolean;
}

// vuex module
class AuthorisationsStore implements Module<IAuthorisationsStoreState, IRootState> {
  namespaced = true;

  // actions
  actions = {
    async loadAuthorisations({ rootState }: ActionContext<IAuthorisationsStoreState, IRootState>, policies: string[]): Promise<IsAuthorisedDto> {
      return await rootState.container.clientAuthorisationApiClient.isAuthorised(policies);
    }
  };
}

export { IAuthorisationsStoreState, AuthorisationsStore };

// export an instance of the store by default
export default new AuthorisationsStore();
